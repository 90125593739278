<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <div v-if="can_list" class="d-flex justify-content-between">
          <b-form-file v-if="readOnly" v-model="file" class="select-file" plain></b-form-file>
          <div class="d-flex justify-content-end w-250" v-if="readOnly">
            <b-button
                class="btn-import mr-1"
                variant="danger"
                @click="handleImport"
            >
              {{ $t('buttons.import') }}
            </b-button>
          </div>
        </div>

        <DxDataGrid
            v-if="can_list"
            id="activity-table"
            ref="data-grid"
            width="100%"
            :show-borders="true"
            :data-source="dataActivity"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            column-resizing-mode="widget"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :hover-state-enabled="true"
            key-expr="id"
            exporting="exportGrid"
            @row-inserted="onInserted"
            @row-updated="onUpdated"
            @row-removed="onRemoved"
            @exporting="onExporting"
        >
          <DxColumn
              type="buttons"
              :allow-exporting="false"
              :caption="$t('table.actions')"
          >
            <DxButton name="edit"/>
            <DxButton name="delete"/>
          </DxColumn>
          <DxColumn
              data-field="wbs_id"
              :name="this.$t('activity.wbsCode')"
              :caption="this.$t('activity.wbsCode')"
          >
            <DxLookup
                :data-source="dataWbs"
                value-expr="id"
                display-expr="code"
            />
          </DxColumn>
          <DxColumn
              data-field="wbs_name"
              :name="this.$t('activity.wbsName')"
              :caption="this.$t('activity.wbsName')"
              :allow-editing="false"
          />
          <DxColumn
              data-field="wbs_mobile_name"
              :name="this.$t('activity.wbsMobileName')"
              :caption="this.$t('activity.wbsMobileName')"
              :allow-editing="false"
          />

          <DxColumn
              data-field="code"
              :name="this.$t('activity.activityCode')"
              :caption="this.$t('activity.activityCode')"
          />
          <DxColumn
              data-field="name"
              :name="this.$t('activity.activityName')"
              :caption="this.$t('activity.activityName')"
          />
          <DxColumn
              data-field="responsible_position"
              :name="this.$t('activity.responsible')"
              :caption="this.$t('activity.responsible')"
          >
            <DxLookup
                :data-source="dataPositions"
                unit
                value-expr="id"
                display-expr="name"
            />
          </DxColumn>
          <DxColumn
              data-field="start"
              data-type="date"
              :name="this.$t('activity.start')"
              :caption="this.$t('activity.start')"
          />
          <DxColumn
              data-field="finish"
              data-type="date"
              :name="this.$t('activity.finish')"
              :caption="this.$t('activity.finish')"
          />
          <DxColumn
              data-field="original_duration"
              data-type="number"
              :name="this.$t('activity.originalDuration')"
              :caption="this.$t('activity.originalDuration')"
          />
          <DxColumn
              data-field="unit_id"
              :name="this.$t('activity.um')"
              :caption="this.$t('activity.um')"
          >
            <DxLookup
                :data-source="dataUnit"
                unit
                value-expr="id"
                display-expr="symbol"
            />
          </DxColumn>
          <DxColumn
              data-field="total_qty"
              data-type="number"
              :name="this.$t('activity.totalQty')"
              :caption="this.$t('activity.totalQty')"
          />
          <DxColumn
              data-field="achieved_qty"
              data-type="number"
              :name="this.$t('activity.achievedQty')"
              :caption="this.$t('activity.achievedQty')"
          />
          <DxColumn
              data-field="manday"
              data-type="number"
              :name="this.$t('activity.manday')"
              :caption="this.$t('activity.manday')"
          />
          <DxColumn
              data-field="boq_id"
              :name="this.$t('activity.boqResourceId')"
              :caption="this.$t('activity.boqResourceId')"
          >
            <DxLookup
                :data-source="dataDropdownBoq"
                value-expr="id"
                display-expr="boq_code"
            />
          </DxColumn>
          <DxColumn
              data-field="wbs_sub_boq_code"
              :name="this.$t('activity.wbs_sub_boq')"
              :caption="this.$t('activity.wbs_sub_boq')"
          />
          <DxColumn
              data-field="boq_um_unit_id"
              :name="this.$t('activity.boqUM')"
              :caption="this.$t('activity.boqUM')"
          >
            <DxLookup
                :data-source="dataUnit"
                unit
                value-expr="id"
                display-expr="symbol"
            />
          </DxColumn>
          <DxColumn
              data-field="boq_coef"
              :name="this.$t('activity.boqCoefficient')"
              :caption="this.$t('activity.boqCoefficient')"
          />
          <DxColumn
              data-field="wastage"
              data-type="number"
              :name="this.$t('activity.wastage')"
              :caption="this.$t('activity.wastage')"
          />
          <DxColumn
              data-field="report_order_1"
              :name="this.$t('activity.reportOrder1')"
              :caption="this.$t('activity.reportOrder1')"
          />
          <DxColumn
              data-field="report_order_1_unit_id"
              :name="this.$t('activity.reportOrder1um')"
              :caption="this.$t('activity.reportOrder1um')"
          >
            <DxLookup
                :data-source="dataUnit"
                value-expr="id"
                display-expr="symbol"
            />
          </DxColumn>
          <DxColumn
              data-field="report_order_1_coef"
              :name="this.$t('activity.reportOrder1Coef')"
              :caption="this.$t('activity.reportOrder1Coef')"
          />
          <DxColumn
              data-field="report_order_2"
              :name="this.$t('activity.reportOrder2')"
              :caption="this.$t('activity.reportOrder2')"
          />
          <DxColumn
              data-field="report_order_2_unit_id"
              :name="this.$t('activity.reportOrder2um')"
              :caption="this.$t('activity.reportOrder2um')"
          >
            <DxLookup
                :data-source="dataUnit"
                value-expr="id"
                display-expr="symbol"
            />
          </DxColumn>
          <DxColumn
              data-field="report_order_2_coef"
              :name="this.$t('activity.reportOrder2Coef')"
              :caption="this.$t('activity.reportOrder2Coef')"
          />
          <DxColumn
              data-field="report_order_3"
              :name="this.$t('activity.reportOrder3')"
              :caption="this.$t('activity.reportOrder3')"
          />
          <DxColumn
              data-field="report_order_3_unit_id"
              :name="this.$t('activity.reportOrder3um')"
              :caption="this.$t('activity.reportOrder3um')"
          >
            <DxLookup
                :data-source="dataUnit"
                value-expr="id"
                display-expr="symbol"
            />
          </DxColumn>
          <DxColumn
              data-field="report_order_3_coef"
              :name="this.$t('activity.reportOrder3Coef')"
              :caption="this.$t('activity.reportOrder3Coef')"
          />
          <DxColumn
              data-field="not_active"
              :name="this.$t('activity.not_active')"
              :caption="this.$t('activity.not_active')"
          />

          <DxFilterRow :visible="true"/>
          <DxSearchPanel :visible="true"/>
          <DxColumnFixing :enabled="true"/>
          <DxColumnChooser :enabled="true"/>
          <DxScrolling mode="standard"/>
          <DxGrouping :context-menu-enabled="true"/>
          <DxGroupPanel :visible="true"/>
          <DxPaging :page-size="20"/>

          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="showPageSizeSelector"
              :show-info="showInfo"
              :show-navigation-buttons="showNavButtons"
          />
          <DxExport
              :enabled="true"
              :visible="true"
              :allow-export-selected-data="false"
          />
          <DxEditing
              :allow-updating="can_edit"
              :allow-deleting="can_delete"
              :allow-adding="can_create"
              mode="row"
              :select-text-on-edit-start="true"
              :use-icons="true"
          />
        </DxDataGrid>
        <h1 v-else>{{ $t('noPermission') }}</h1>
        <DxPopup
            :visible="assignPop"
            :close-on-outside-click="false"
            :show-close-button="false"
            :title="$t('assingPerson')"
        >
          <div class="dx-field">
            <div class="dx-field-label">
              {{ this.$t('activity.activityName') }}
            </div>
            <div class="dx-field-value">
              <DxTextArea v-model="rowData.name" :read-only="true"></DxTextArea>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              {{ this.$t('activity.activityCode') }}
            </div>
            <div class="dx-field-value">
              <DxTextArea v-model="rowData.code" :read-only="true"></DxTextArea>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ this.$t('person') }}</div>
            <div class="dx-field-value">
              <DxSelectBox
                  v-model="rowData.user"
                  :search-enabled="true"
                  :items="dataUsers"
                  display-expr="username"
                  value-expr="id"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-value">
              <b-button
                  class="btn-import mr-1"
                  variant="success"
                  @click="saveAssign()"
              >Assign
              </b-button
              >
              <b-button
                  class="btn-import mr-1"
                  variant="danger"
                  @click="closeAddingPop()"
              >Close
              </b-button>
            </div>
          </div>
        </DxPopup>
      </div>
    </div>
    <b-modal
        ref="import-modal"
        centered
        title="Import"
        ok-only
        :ok-title="$t('buttons.ok')"
    >
      <b-card-text>{{ $t('general.importStarted') }}</b-card-text>
      <b-card-text v-if="importResult.length !== 0">
        {{ importResult }}
      </b-card-text>
      <div class="d-flex justify-content-center">
        <b-spinner v-if="importResult.length === 0"/>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import axios from '@axios';
import {
  BCard,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BButton,
  BModal,
  BCardText,
  BSpinner
} from 'bootstrap-vue';
import {
  DxDataGrid,
  DxButton,
  DxColumn,
  DxLookup,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxPaging,
  DxEditing,
  DxExport,
  DxPager
} from 'devextreme-vue/data-grid';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import {exportDataGrid} from 'devextreme/excel_exporter';
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import 'jspdf-autotable';
import {DxPopup} from 'devextreme-vue/popup';
import {DxTextArea} from 'devextreme-vue/text-area';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {readOnly} from "@/auth/utils";

export default {
  components: {
    DxButton,
    BCardText,
    BFormFile,
    BButton,
    BModal,
    BSpinner,
    DxLookup,
    DxEditing,
    DxFilterRow,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    DxExport,
    BCard,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxPopup,
    DxTextArea,
    DxSelectBox,
    DxPager
  },
  data() {
    return {
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      displayModes: [
        {text: "Display Mode 'full'", value: 'full'},
        {text: "Display Mode 'compact'", value: 'compact'}
      ],
      displayMode: 'full',
      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      error: '',
      dataLoading: true,
      dataUsers: [],
      dataPositions: [],
      dataUnit: [],
      dataCurrency: [],
      dataDropdownBoq: [],
      dataActivity: [],
      dataWbs: [],
      selectedLanguage: {},
      file: null,
      importResult: '',
      assignPop: false,
      rowData: {
        name: '',
        code: '',
        user: 0
      },
      readOnly: false
    };
  },
  async mounted() {
    this.readOnly = readOnly();
    await this.getPageAuths();
    await this.getLanguage();
    await this.getUnit();
    await this.getCurrency();
    await this.getWbs();
    await this.getBoqData();
    await this.getActivityData();
    // await this.getUserData();
    await this.getPositionData();
  },
  methods: {
    async saveAssign() {
      try {
        await axios.post('/activity-assing', {
          id: this.rowData.id,
          user: this.rowData.user
        });
      } catch (e) {
        this.error = e?.response?.data?.message || e?.message;
      }
      this.closeAddingPop();
      await this.getActivityData();
    },
    async getUserData() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-site-user');

      try {
        this.dataUsers = response?.data?.result || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getPositionData() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-position');

      try {
        this.dataPositions = response?.data || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    },
    closeAddingPop() {
      this.assignPop = false;
      this.rowData = {
        name: '',
        code: '',
        user: 0
      };
    },
    async getPageAuths() {
      await axios.get('/hierarchy');
      const pageAuths = await axios.post('/get-page-auth', {
        page: 'Activity'
      });
      this.can_edit = this.readOnly && pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = this.readOnly && pageAuths.data.can_delete;
      this.can_create = this.readOnly && pageAuths.data.can_create;
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
            (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUnit() {
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataUnit = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getCurrency() {
      this.error = '';
      const response = await axios.get('/list-currency');

      try {
        this.dataCurrency = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getWbs() {
      this.error = '';
      const response = await axios.get('/list-wbs?noHi=true');

      try {
        this.dataWbs = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getBoqData() {
      this.error = '';
      const response = await axios.get('/list-dropdown-boq');

      try {
        this.dataDropdownBoq = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getActivityData() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-activity');

      try {
        this.dataActivity = response?.data?.result || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async refreshList() {
      await this.getLanguage();
      await this.getUnit();
      await this.getCurrency();
      await this.getWbs();
      await this.getBoqData();
      await this.getActivityData();
    },
    showToast({variant = 'success', title, text, icon = 'XIcon'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Fama-Activity');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = {name: 'Arial', size: 12};
          excelCell.alignment = {horizontal: 'left'};
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
              new Blob([buffer], {type: 'application/octet-stream'}),
              'Fama-Activity.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    closeAllMasterDetail() {
      this.$refs['data-grid'].instance.collapseAll(-1);
    },
    onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
      this.getActivityData();
    },
    handleImport() {
      if (!this.file) {
        this.showToast({
          variant: 'danger',
          title: this.$t('modal.error'),
          text: this.$t('modal.please-choose-file-first')
        });
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.importResult = '';
      this.$refs['import-modal'].show();
      this.showToast({
        variant: 'info',
        icon: 'InfoIcon',
        text: this.$t('modal.start-import')
      });
      axios
          .post(`import-activity`, formData)
          .then((response) => {
            if (response.status === 200) {
              this.importResult = this.$t('general.importSuccess');
              this.showToast({
                variant: 'success',
                text: this.importResult
              });
              this.refreshList();
            } else {
              this.importResult = this.$t('general.importError');
              this.showToast({
                variant: 'danger',
                text: this.importResult
              });
            }
          })
          .catch((err) => {
            this.importResult = err?.response?.data || err?.message;
            this.showToast({
              variant: 'danger',
              text: this.importResult
            });
          });
    },
    onAssign(e) {
      this.assignPop = true;
      this.rowData = e.row.data;
    },
    onRemoved(e) {
      axios
          .post(`delete-activity/${e.data.id}`, {})
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla silindi.'
            });
            this.refreshList();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    onUpdated(e) {
      const {
        wbs_id,
        boq_id,
        unit_id,
        responsible_position,
        code,
        start,
        finish,
        original_duration,
        achieved_qty,
        total_qty,
        manday,
        wastage,
        boq_um_unit_id,
        boq_coef,
        report_order_1_unit_id,
        report_order_1_coef,
        report_order_2_unit_id,
        report_order_2_coef,
        report_order_3_unit_id,
        report_order_3_coef,
        not_active,
        name,
        report_order_1,
        report_order_2,
        report_order_3
      } = e.data;
      axios
          .post(`edit-activity/${e.data.id}`, {
            wbs_id,
            boq_id,
            unit_id,
            responsible_position,
            code,
            start,
            finish,
            original_duration,
            achieved_qty,
            total_qty,
            manday,
            wastage,
            boq_um_unit_id,
            boq_coef,
            report_order_1_unit_id,
            report_order_1_coef,
            report_order_2_unit_id,
            report_order_2_coef,
            report_order_3_unit_id,
            report_order_3_coef,
            not_active,
            name,
            report_order_1,
            report_order_2,
            report_order_3
          })
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla güncellendi.'
            });
            this.refreshList();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    onInserted(e) {
      const {
        wbs_id,
        boq_id,
        unit_id,
        responsible_position,
        code,
        start,
        finish,
        original_duration,
        achieved_qty,
        total_qty,
        manday,
        wastage,
        boq_um_unit_id,
        boq_coef,
        report_order_1_unit_id,
        report_order_1_coef,
        report_order_2_unit_id,
        report_order_2_coef,
        report_order_3_unit_id,
        report_order_3_coef,
        name,
        report_order_1,
        report_order_2,
        report_order_3
      } = e.data;

      axios
          .post('create-activity', {
            wbs_id,
            boq_id,
            unit_id,
            responsible_position,
            code,
            start,
            finish,
            original_duration,
            achieved_qty,
            total_qty,
            manday,
            wastage,
            boq_um_unit_id,
            boq_coef,
            report_order_1_unit_id,
            report_order_1_coef,
            report_order_2_unit_id,
            report_order_2_coef,
            report_order_3_unit_id,
            report_order_3_coef,
            name,
            report_order_1,
            report_order_2,
            report_order_3
          })
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla eklendi.'
            });
            this.refreshList();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    }
  }
};
</script>

<style lang="scss">
@import './tables.scss';

.dx-master-detail-cell {
  background-color: bisque !important;
}

.fama-table > input {
  width: auto;
}
</style>
